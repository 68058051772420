<template>
  <div>
  <vs-sidebar
    position-right
    parent="body"
    default-index="1"
    color="primary"
    class="add-new-data-sidebar items-no-padding"
    spacer
    v-model="sidebarActive">
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>{{ !this.roleId ? $t('Add New Role') : $t('Update Role') }}</h4>
      <feather-icon icon="XIcon" @click.stop="sidebarActive = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>
    <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
      <div class="p-3 mt-5">
        <vs-input class="w-full" v-model="selectedRolePermissions.name" :label-placeholder="$t('Role Name')"/>
        <vs-alert color="danger" v-show="errors.name" v-if="errors.name" active="true">
          {{$t(errors.name[0])}}
        </vs-alert>
      </div>
      <vs-row vs-w="12">
        <vs-col class="p-5" :key="permissionGroup.id" v-for="(permissionGroup, key) in permissions">
          <vs-collapse>
            <vs-collapse-item>
              <div slot="header">
                <h5>{{ key }}</h5>
                <vs-divider/>
              </div>
              <vs-row vs-w="12">
                <vs-col vs-w="6" :key="permission.id" v-for="permission in permissionGroup">
                  <vs-checkbox v-model="selectedRolePermissions.permissions" class="m-1" color="primary" :vs-value="permission.id">
                    {{ permission.name }}
                  </vs-checkbox>
                </vs-col>
              </vs-row>
            </vs-collapse-item>
          </vs-collapse>

        </vs-col>
      </vs-row>

    </component>
    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button icon-after @click="saveRole()" class="mr-6" type="gradient" color="success">{{$t('Save')}}</vs-button>
      <vs-button icon-after type="gradient"  color="danger" @click="sidebarActive = false">{{$t('Cancel')}}</vs-button>
    </div>
  </vs-sidebar>
  </div>
</template>

<script>

/* eslint-disable */
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
  props:['roleId','isSidebarActive'],
  components: {
    VuePerfectScrollbar
  },
  data () {
    return {
      selectedRolePermissions:{
        name:'',
        permissions:[]
      },
      sidebarActive : false,
      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60
      },
      permissionsModels:[],
      errors:[]
    }
  },
  watch: {
    sidebarActive (val){
      if(!val)
        setTimeout(()=>{
          this.$store.dispatch('role/initState')
          this.$emit('close-sidebar')
        },1)
    },
    permissions(){
      this.permissionsModels = Object.keys(this.permissions)
    },
    rolePermissions(val){
      this.selectedRolePermissions = {...val}
    }
  },
  computed: {
    permissions () {
      return this.$store.getters['role/permissions']
    },
    rolePermissions() {
      return this.$store.getters['role/rolePermissions']
    },
    scrollbarTag () { return this.$store.getters.scrollbarTag },

  },
  methods: {
    getData(){
      let args = ''
      this.$store.dispatch("role/getGroupedPermissions", args)
      setTimeout(()=>{
        this.sidebarActive = true
        if(this.roleId){
          this.$store.dispatch('role/getRolePermissions', this.roleId)
        }
      },1)
    },
    saveRole: function () {
      if (!this.roleId){
        this.$store.dispatch("role/storeRole", this.selectedRolePermissions).then(res =>{
          this.sidebarActive = false
        }).catch((error) =>{
          if(error.response.data.errors)
            this.errors = error.response.data.errors
        })
      } else {
        this.$store.dispatch("role/updateRole",this.selectedRolePermissions).then(res =>{
          this.sidebarActive = false
        }).catch((error) =>{
          if(error.response.data.errors)
            this.errors = error.response.data.errors
        })
      }
    }
  },
  created() {
    this.getData()
  }
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

  &:not(.ps) {
    overflow-y: auto;
  }
}
</style>
