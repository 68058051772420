<template>
  <div>
    <vs-popup :title="$t('Filter with')" :active.sync="popupActive">
      <h3></h3>
      <vs-tabs v-model="activeTab">
        <vs-tab :label="$t('Permissions')">
          <div>
            <my-select               :placeholder="$t('select Permissions')"
              class="selectExample mt-5 py-1 mb-2"
              style="width: 100%"
              multiple
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              v-model="selectedPermissions">
              <vs-select-item :key="index" :value="item.id" :text="item.name"
                              v-for="(item,index) in permissions"/>
            </my-select>
          </div>
        </vs-tab>
        <vs-tab :label="$t('Models')">
          <div>
            <my-select               :placeholder="$t('select Permissions')"
              class="selectExample mt-5 py-1 mb-2"
              style="width: 100%"
              multiple
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              v-model="selectedModels">
              <vs-select-item :key="index" :value="item" :text="item"
                              v-for="(item,index) in models"/>
            </my-select>
          </div>
        </vs-tab>
      </vs-tabs>
      <vs-button @click="filter()">{{$t('Filter')}}</vs-button>
    </vs-popup>
  </div>
</template>

<script>
/* eslint-disable */
import vSelect from 'vue-select'
import MySelect from '../../../components/MySelect'

export default {
  name: "FilterRoles",
  components: {
    'v-select': vSelect,
    MySelect
  },
  data() {
    return {
      popupActive: true,
      selectedPermissions: [],
      selectedModels: [],
      activeTab:0
    }
  },
  watch: {
    popupActive(val) {
      if (!val)
        setTimeout(() => {
          this.$emit('close-popup')
        }, 1)
    },
    activeTab(){
      this.initVals()
    }
  },
  computed: {
    permissions(){
      return this.$store.getters["role/permissions"]
    },
    models(){
      return this.$store.getters["role/models"]
    }
  }
  ,
  created() {
    this.getData()
  },
  methods:{
    getData(){
      this.$store.dispatch("role/getPermissions")
      this.$store.dispatch("role/getModels")
    },
    initVals(){
      this.selectedModels = []
      this.selectedPermissions = []
    },
    filter(){
      this.$emit('toggle-filter')
      this.popupActive = false
      if(this.activeTab === 0){
        this.$store.dispatch("role/filterByPermissions", this.selectedPermissions)
      } else {
        this.$store.dispatch("role/filterByModels", this.selectedModels)
      }
    }
  }
}
</script>

<style scoped>
  .test div {
    overflow: visible !important;
  }
</style>
