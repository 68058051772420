<template>
  <div>
    <vs-popup :title="$t('Permissions')" :active.sync="popupActive">
      <vs-row vs-w="12">
        <vs-col class="p-5" :key="key" v-for="(permissionGroup,key) in permissions">
          <vs-collapse>
            <vs-collapse-item :open="false">
              <div slot="header">
                <h5>{{key}}</h5>
                <vs-divider/>
              </div>
              <vs-row vs-w="12">
                <vs-chip class="m-1" :key="permission.id" v-for="permission in permissionGroup" color="primary">
                  {{ permission.name }}
                </vs-chip>
              </vs-row>
            </vs-collapse-item>
          </vs-collapse>
        </vs-col>
      </vs-row>
    </vs-popup>
  </div>
</template>

<script>

/* eslint-disable */
export default {
  name: "ViewPermissions",
  props: ['permissionArgs'],
  data () {
    return{
      permissionsModels:[],
      popupActive:true,
    }
  },
  watch: {
    permissions () {
      this.permissionsModels = Object.keys(this.permissions)
    }
    ,popupActive(val){
      if(!val)
        setTimeout(()=>{
        this.$emit('close-popup')
        },1)
    },
  },
  computed:{
    permissions: function() {
      return this.$store.getters["role/permissions"]
    }
  },
  methods:{
    getData(){
      this.$store.dispatch('role/getGroupedPermissions', this.permissionArgs)
    }
  },
  created() {
    this.getData()
  }
}
</script>

<style scoped>

</style>
