<template>
  <div>
    <view-permissions v-if="permissionsPopup" :permissionArgs="permissionArgs"
                      @close-popup="closePopUp"/>
    <sidebar-role v-if="newRoleSidebar" @close-sidebar="toggleDataSidebar" :roleId="roleId"/>
    <filter-roles v-if="filterPopup" @close-popup="toggleFilterPopup" @toggle-filter="toggleFilter"/>

    <vs-row class="pt-6">
      <vs-col vs-w="6" vs-type="flex">
        <vs-button v-if="this.isFiltered" color="danger" type="border" class="mr-4" @click="toggleFilter(false)">
          <font-awesome-icon svgClasses="h-4 w-4" class="mr-2" :icon="['fas', 'times']"/>
          {{$t('Clear Filter')}}
        </vs-button>
        <vs-button color="primary" type="border" class="mr-4" @click="toggleFilterPopup">
          <font-awesome-icon svgClasses="h-4 w-4" class="mr-2" :icon="['fas', 'filter']"/>
          {{$t('Filter')}}
        </vs-button>
        <vs-button color="success" type="gradient" icon-pack="feather" icon-after icon="icon-plus"
                   @click="addNewRole()"> {{$t('new role')}}
        </vs-button>
      </vs-col>
      <vs-col vs-w="6" vs-type="flex" vs-justify="flex-end">
        <vs-button @click="$router.push({name: 'trashed_roles'}).catch(() => {})"  type="gradient" color="danger">{{$t('Trash')}}
          <font-awesome-icon :icon="['far', 'trash-alt']"/>
        </vs-button>
      </vs-col>
    </vs-row>
    <vs-row class="pt-12">
      <vs-col>
        <template>
          <div id="data-list-list-view" class="data-list-container">
            <vs-table search max-items="5" pagination :data="roles">
              <template slot="header">
                <h3>{{$t('Roles')}}</h3>
              </template>
              <template slot="thead">
                <vs-th>{{$t('Id')}}</vs-th>
                <vs-th>{{$t('Role')}}</vs-th>
                <vs-th>{{$t('Permissions')}}</vs-th>
                <vs-th>{{$t('Models')}}</vs-th>
                <vs-th>{{$t('Actions')}}</vs-th>
              </template>
              <template slot-scope="{data}">
                <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td :data="tr">
                    <vs-chip color="primary">
                      {{ tr.id }}
                    </vs-chip>
                  </vs-td>
                  <vs-td :data="tr">
                    {{ tr.name }}
                  </vs-td>
                  <vs-td :data="tr">
                    <vs-button v-if="tr.permission_count>0" @click="openPopUp(tr.id)"
                               color="primary" type="border">{{$t('Granted permissions')}}
                    </vs-button>
                    <vs-button v-else @click="editRole(tr.id)" icon-pack="feather" icon-after icon="icon-plus"
                               color="warning" type="border">{{$t('Add permissions')}}
                    </vs-button>
                  </vs-td>
                  <vs-td :data="tr">
                    <vs-button @click="openPopUp(tr.id,model)" type="line" class="m-1" :key="model.index"
                               v-for="model in tr.models">{{ model }}
                      <font-awesome-icon :icon="['fas', 'list']"/>
                    </vs-button>
                  </vs-td>
                  <vs-td :data="tr">
                    <a class="mx-1" @click="editRole(tr.id)" href="javascript:void(0)">
                      <font-awesome-icon
                        color="rgb(var(--vs-success))"
                        :icon="['fas', 'edit']"
                      />
                    </a>
                    <a class="mx-1" @click="deleteRole(tr.id)" href="javascript:void(0)">
                      <font-awesome-icon
                        color="rgb(var(--vs-danger))"
                        :icon="['fas', 'trash-alt']"
                      />
                    </a>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
        </template>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>

/* eslint-disable */
import ViewPermissions from "@/views/pages/role/ViewPermissions";
import SidebarRole from "@/views/pages/role/SidebarRole";
import FilterRoles from "@/views/pages/role/FilterRoles";

export default {

  name: 'Permissions',
  components: {FilterRoles, ViewPermissions, SidebarRole},
  data() {
    return {
      isFiltered:false,
      isNewUser: false,
      permissionsPopup: false,
      filterPopup: false,
      permissionArgs: [],
      newRoleSidebar: false,
      roleId: ''
    }
  },
  created() {
    this.getData()
  }, computed: {
    roles() {
      return this.$store.getters["role/roles"]
    }
  },
  methods: {
    getData(){
      this.$store.dispatch('role/getRoles')
    },
    deleteRole: function (role_id) {
      this.$store.dispatch('role/deleteRole', role_id)
    },
    addNewRole() {
      this.roleId = ''
      this.toggleDataSidebar(true)
    },
    editRole(role_id) {
      this.roleId = role_id
      this.toggleDataSidebar(true)
    },
    toggleDataSidebar(val = false) {
      this.newRoleSidebar = val
    },
    openPopUp(...args) {
      this.permissionArgs = args
      this.permissionsPopup = true
    },
    closePopUp() {
      this.permissionsPopup = false
    },
    toggleFilterPopup() {
      this.filterPopup = !this.filterPopup
    },
    toggleFilter(val = true){
      this.isFiltered = val
      if (val === false){
        this.getData()
      }
    }
  },
}
</script>

<style lang="scss">
#data-list-list-view {
  .vs-con-table {

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .05);

        td {
          padding: 20px;

          &:first-child {
            border-top-left-radius: .5rem;
            border-bottom-left-radius: .5rem;
          }

          &:last-child {
            border-top-right-radius: .5rem;
            border-bottom-right-radius: .5rem;
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }
  }
}
</style>
